<template>
  <ion-page>
    <toolbar></toolbar>
    <!-- fab placed to the bottom end -->
    <ion-fab 
      class="fab" 
      vertical="top" 
      horizontal="end" 
      slot="fixed"
      >
      <ion-fab-button @click.prevent="addNewPost">
        <ion-icon :icon="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-content fullscreen > 
      <ion-grid >
        <ion-row class="ion-justify-content-around">
        <ion-col  size-lg="10">
          <div class="card-title">{{ state.menu.title }}</div>
          <div class="card-subtitle">{{ state.menu.description }}</div>
          <ion-searchbar @search="searchInput" v-model="state.search"></ion-searchbar>
          <ion-row>
            <ion-col size-md="6" size-xs="12" 
              v-for="post in state.posts"
              :key="post.id"
              class="post"
              >
              <ad-item :item="post"></ad-item>
            </ion-col>
          </ion-row>
        </ion-col>
        </ion-row>
      </ion-grid>
      <ion-infinite-scroll 
        threshold="50px" 
        @ionInfinite="loadData"
        position="bottom">
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>      
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent,
         IonGrid, IonRow, IonCol,
         IonInfiniteScroll, IonInfiniteScrollContent,
         IonSearchbar,
         IonFab, IonFabButton,
         IonIcon
         // IonTitle
         // IonButton
       } from '@ionic/vue'
import { defineComponent, reactive, onMounted,
         watch, onUpdated, nextTick, inject
        } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
// import axios from 'axios'
import AdItem from '@/components/AdItem.vue'
// import mainmenu from '@/utils/general'
import { useRoute, useRouter } from 'vue-router'
import { addOutline } from 'ionicons/icons'
import { openToast } from '@/utils'
import axios from '@/utils/api'

export default defineComponent({
  name: 'Post',
  components: { 
        IonPage, IonContent,
        IonGrid, IonRow, IonCol,
        Toolbar, AdItem, 
        IonInfiniteScroll, IonInfiniteScrollContent,
        IonSearchbar,
        IonFab, IonFabButton,
        IonIcon,
        // IonTitle,
        // IonButton,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const loadSpin = inject('loadSpinner')
    let query = route.query
    const addNewURL = `/${route.params.countrycode}/new/${route.params.category}/`

    const state = reactive({
        menu: {},
        search: decodeURIComponent(route.query.q || ""),
        posts: [],
        nextpage: `/${route.params.countrycode}/p/${route.params.category}/`
    })
    const menus = [
     { 
       code: "property",
       title: "Property - Pinoy Room For Rent",
       subtitle: "Rent Unit, Room, and Bedspace",
       // image: imgProperty,
       description: "Find your next home and make new connection. Pinoy Room For Rent starts here. ",
       url: `/${route.params.category}property/`
     },
     { 
       code: "handyman",
       title: "Handyman",
       subtitle: "Handyman Service",
       description: "If you need plumber, carpenter, locksmith, or just about any help, find them here.",
       // image: imgHandyman,
       url: `/${route.params.category}/handyman/`
     },
     {
       code: "lorry",
       title: "Lorry",
       subtitle: "Moving",
       description: "Whether you are moving houses or need IKEA delivery, help is here.",
       // image: imgLorry,
       url: `/${route.params.category}/lorry/`
     },
     {
       code: "all",
       title: "All Ads",
       subtitle: "Browse all ads",
       description: "Find what you need from here.",
       // image: imgLorry,
       url: `/${route.params.category}/all/`
     },
    ]

    function addNewPost() {
      router.push(addNewURL)
    }

    function fetchNextPage() {
      // console.log(`fetchNextPage: ${state.nextpage}`)
      if (state.nextpage != "null") {
        // const api = `${state.nextpage}`
        // console.log(api)
        // console.log(query)
        axios.get(state.nextpage, { params: query })
        .then(res => {
          // replace http to https as a temporary workaround
          state.nextpage = String(res.data.next).replace('http:','https:')
          state.posts = [...state.posts, ...res.data.result]
        })
        .catch(error => {
          loadSpin.status = false
          openToast(error.message, "", "Error", "middle", "danger" )
        })
      }
    }

    async function reloadData() {
      state.posts = []
      state.nextpage = `/${route.params.countrycode}/p/${route.params.category}/`
      await fetchNextPage()
    }

    async function loadData(evt) {
      await fetchNextPage()
      evt.target.complete()
    }

    onMounted(() => {
      // console.log('mounted')
      // console.log(query)
      state.menu = menus.find(x => x.code === route.params.category)
      reloadData()
    })

    function searchInput(evt) {
      const queryInput = encodeURI(evt.target.value) || ""
      query = { ...route.query }
      if (queryInput) {
      //   state.nextpage += `?q=${queryInput}`
        query.q = queryInput
      } else {
        delete query.q
      }
      router.push({
        name: 'listPosts',
        params: {
          countrycode: route.params.countrycode,
          category: route.params.category
        },
        query: query
        })
      // fetchNextPage()
    }

    onUpdated(() => {
      nextTick( () => {
        loadSpin.status = false
      })
    })

    // fetch the user information when params change
    watch(
      () => route.query,
      async newQuery => {
        // console.log('newQuery')
        // console.log(newQuery)
        // state.nextpage= `/${route.params.countrycode}/p/${route.params.category}/`
        // console.log('nextpage')
        // console.log(state.nextpage)
        query = newQuery
        state.search = decodeURIComponent(query.q || "")
        // check if navigating out
        if (route.name === "listPosts"){
          await reloadData()
        }
      }
    )

    return { state, route, fetchNextPage, loadData, 
             searchInput, addOutline, addNewURL,
             addNewPost 
            }
  }
})

</script>

<style scoped>
ion-searchbar {
  --background: whitesmoke !important;
  --border-radius: 10px !important;
  --color: var(--ion-color-primary-text-dark) !important;
  --icon-color: var(--ion-color-primary-text-dark) !important;
  --box-shadow: 0px 0px 0px #aaaaaa !important;
}

.fab {
  margin-top: 50px !important;
  margin-right: 10px;
}

ion-fab-button {
  --background: var(--ion-color-primary-shade);
}
</style>